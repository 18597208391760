<template>

  <div>
    <div class="block-header">
    </div>

    <div class="container-fluid">
      <div class="row clearfix">
        <div class="col-lg-12">
          <div class="card">
            <div class="body">

              <div>
                <el-button v-if="operation!=='查看'" type="primary" size="medium" style="float: right;" @click="submit">
                  {{ operation }}
                </el-button>
                <el-page-header @back="go(`/project-tracking-list/${page}`)" :content="'跟踪洽谈项目--'+operation">
                </el-page-header>
              </div>

              <el-form ref="form" label-width="160px" size="small">
                <el-row>
                  <el-col :span="10">
                    <el-form-item label="填报单位">
                      <el-tag type="success">{{ form.填报单位 }}</el-tag>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="10">
                    <el-form-item label="项目名称" required>
                      <el-input v-model="form.项目名称" placeholder="请输入项目名称" size="small"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <el-tooltip content="快捷选择 `有效项目` 以填充当前项目信息" placement="top">
                      <el-button icon="el-icon-magic-stick" type="text"
                                 style="color: orangered;margin-left: 10px;" size="medium"
                                 @click="dialogVisible=true"></el-button>
                    </el-tooltip>
                  </el-col>

                </el-row>

                <el-row>
                  <el-col :span="10">
                    <el-form-item label="建设内容" required>
                      <el-input type="textarea" :rows="4" v-model="form.建设内容"></el-input>
                    </el-form-item>
                  </el-col>

                  <el-col :span="10">
                    <el-form-item label="拟投资额(万元)" required>
                      <el-input-number :min="1" v-model="form.总投资额"></el-input-number>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="10">
                    <el-form-item label="投资方名称" required>
                      <el-input v-model="form.投资方名称"></el-input>
                    </el-form-item>
                  </el-col>

                  <el-col :span="10">
                    <el-form-item label="产业类别" required>
                      <el-select v-model="form.产业类别">
                        <el-option label="工业" value="工业"></el-option>
                        <el-option label="农业" value="农业"></el-option>
                        <el-option label="服务业" value="服务业"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="10">
                    <el-form-item label="建设地点" required>
                      <el-input v-model="form.建设地点"></el-input>
                    </el-form-item>
                  </el-col>

                  <el-col :span="10">
                    <el-form-item label="用地面积" required>
                      <el-input v-model="form.用地面积"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>


                <el-row>

                  <el-col :span="10">
                    <el-form-item label="投资方所属地" required>
                      <place-select v-model="form.投资方所属地"></place-select>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>

                  <el-col :span="10">
                    <el-form-item label="联系人" required>
                      <el-input v-model="form.联系人"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <el-form-item label="联系人电话" required>
                      <el-input v-model="form.联系人电话"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <el-form-item label="联系人职务" required>
                      <el-input v-model="form.联系人职务"></el-input>
                    </el-form-item>
                  </el-col>

                </el-row>

                <el-row>

                  <el-col :span="10">
                    <el-form-item label="跟踪人" required>
                      <el-input v-model="form.跟踪人"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <el-form-item label="跟踪单位" required>
                      <el-input v-model="form.跟踪单位"></el-input>
                    </el-form-item>
                  </el-col>

                </el-row>

                <el-row>

                  <el-col :span="12">
                    <el-form-item label="是否来往考察" required>
                      <el-radio-group v-model="form.是否来往考察">
                        <el-radio-button label="已来冶考察"></el-radio-button>
                        <el-radio-button label="已考察对方公司"></el-radio-button>
                        <el-radio-button label="双方互相考察"></el-radio-button>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="16">

                    <el-form-item label="考察图片" required>
                      <el-tag effect="plain" type="danger" style="margin-bottom: 5px;">上传1-6张考察图片
                      </el-tag>
                      <multiple-file-upload v-model="form.考察图片" :file-format="['.png','.jpg']">
                      </multiple-file-upload>
                    </el-form-item>

                  </el-col>
                </el-row>


                <el-row>

                  <el-col :span="10">
                    <el-form-item label="项目进展情况" required>
                      <el-input v-model="form.项目进展情况"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <el-form-item label="项目存在问题" required>
                      <el-input v-model="form.项目存在问题"></el-input>
                    </el-form-item>
                  </el-col>

                </el-row>

                <el-form-item v-if="operation!=='审批' && operation!=='填报'" label="审批信息">
                  <el-form-item>
                    {{ form.审批状态 + " " + (form.审批意见 ? form.审批意见 : '') }}
                  </el-form-item>
                </el-form-item>

                <el-row v-if="operation==='审批'">
                  <el-col :span="18">
                    <el-form-item label="审批">
                      <el-radio-group v-model="form.审批状态">
                        <el-radio-button label="待审批"></el-radio-button>
                        <el-radio-button label="审批通过"></el-radio-button>
                        <el-radio-button label="审批未通过"></el-radio-button>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row v-if="operation==='审批'">
                  <el-col :span="18">
                    <el-form-item label="审批意见">
                      <el-input v-model="form.审批意见" :autosize="{ minRows: 2, maxRows: 4}"
                                type="textarea"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>

              </el-form>


            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="选择有效项目" :visible.sync="dialogVisible" width="30%" :modal="false">
      <el-select v-model="select_project" :multiple="false" filterable remote reserve-keyword
                 placeholder="请输入项目名称关键词" :remote-method="remoteRetrieve" :loading="loading" size="small"
                 style="width: 100%" @change="handleProjectChange">
        <el-option v-for="(item,index) in retrieves" :key="item.unique" :label="(index+1)+'. '+item.项目名称"
                   :value="item">
        </el-option>
      </el-select>
    </el-dialog>

  </div>

</template>

<script>
import PlaceSelect from '../components/PlaceSelect.vue'
import projectEffectiveController from '../controller/projectEffectiveController.js'
import projectTrackingController from "../controller/projectTrackingController.js"
import MultipleFileUpload from '../components/MultipleFileUpload.vue';
import {projectTrackingValidate} from '@/assets/js/check.js'

export default {
  components: {
    MultipleFileUpload, PlaceSelect
  },
  data() {
    return {
      operation: '查看',
      id: '',
      page: 1,
      form: {
        考察图片: [],
      },
      isApproval: false,
      user: {},
      retrieves: [],
      loading: false,
      dialogVisible: false,
      select_project: {},
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
    }
  },
  created() {
    this.id = this.getParam('id');
    this.page = this.getParam('page');
    this.isApproval = this.getParam('approval') === 'approval'
    this.user = JSON.parse(sessionStorage.getItem('user'))

    if (this.isApproval) {
      this.operation = '审批'
      this.initForm();
    } else if (this.id) {
      this.operation = '修改'
      this.initForm();
    } else {
      this.operation = '填报'
      this.form.填报单位 = this.user.dept_name
    }
  },
  methods: {
    handleProjectChange(obj) {

      this.form.项目名称 = obj.项目名称;
      this.form.建设地点 = obj.建设地点;

      this.form.建设内容 = obj.建设内容;
      this.form.产业类别 = obj.产业类别;
      this.form.用地面积 = obj.用地面积;
      this.form.投资方名称 = obj.投资方名称;
      this.form.总投资额 = obj.总投资额;
      this.form.投资方所属地 = obj.投资方所属地;
      this.form.联系人 = obj.联系人;
      this.form.联系人电话 = obj.联系人电话;
      this.form.联系人职务 = obj.联系人职务;
      this.form.跟踪人 = obj.跟踪人;
      this.form.跟踪单位 = obj.跟踪单位;

      this.form = this.deepClone(this.form);
    },
    remoteRetrieve(query_keyword) {
      this.loading = true;
      projectEffectiveController.retrieve({
        query_keyword: query_keyword
      }).then(res => {
        this.loading = false;
        this.retrieves = res.data;
      })
    },
    initForm() {
      projectTrackingController.get({
        unique: this.id
      }).then(res => {
        this.form = res.data
        if (this.form.modified.status < 0) {
          this.operation = '查看';
        }
      })
    },
    submit() {
      if (!projectTrackingValidate(this.form, this))
        return;

      let obj = this.deepClone(this.form)

      if (this.isApproval) {
        projectTrackingController.approval(obj).then(res => {
          this.go(`/project-tracking-list/${this.page}`)
        })
      } else if (obj.id) {
        projectTrackingController.put(obj).then(res => {
          this.go(`/project-tracking-list/${this.page}`)
        })
      } else {
        projectTrackingController.post(obj).then(res => {
          this.go(`/project-tracking-list/${this.page}`)
        })
      }
    },
  }

}
</script>

<style scoped>
.el-page-header {
  margin-bottom: 40px;
}

.el-date-picker {
  cursor: pointer;
}
</style>
